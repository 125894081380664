<!--
 * @version: 1.0
 * @Date: 2024-01-24 15:17:11
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="urg-btn" @click="urgMethod">
    <img src="@/assets/images/urg-icon.png" alt srcset />
    <span>催办</span>
  </div>
</template>

<script setup>
import { useUrg } from './useUrg.js'
import { useAttrs, onMounted, ref, inject, computed } from 'vue'
import { useRoute } from 'vue-router'
import { toUrge } from '@/apis/seal'
import { cmsNotice } from '@/utils/utils'
const getDetail = inject('getDetail')
const route = useRoute()
const documentId = ref()
const props = defineProps({
  tipsType: {
    type: String,
    default: 'approve' //默认值审批催办   staff 盖印人催办
  },
  processMold: {
    type: Number,
    default: 1
  }
})
const { urgModel } = useUrg(props.tipsType)
const emit = defineEmits(['urgOk'])
console.log(useAttrs(), '我是useAttrs')
const urgInfo = useAttrs()
const urgMethod = () => {
  console.log('脆啵啵')
  urgModel(async () => {
    console.log('点击确定催办', urgInfo, documentId.value)
    // 催办结果 后面这里请求接口
    const { sealId, staffIds, stage, dataId } = urgInfo
    if (props.processMold == 2) {
      //归档催办
      const dataid = dataId[0]
      const params = {
        processMold: 2,
        dataId: dataid,
        staffIds,
        stage,
        staffIds,
        type: props.tipsType == 'approve' ? 1 : 0
      }
      try {
        var result = await toUrge(params)
        console.log('催办结果', result.success)
        if (result.success) {
          cmsNotice('success', '催办成功')
        }
        getDetail()
        emit('urgOk', result.success)
      } catch (e) {
        // cmsNotice('warning', '催办失败')
        emit('urgOk', false)
      }
    } else {
      const params = {
        type: props.tipsType == 'approve' ? 1 : 0,
        documentId: documentId.value,
        sealId,
        stage,
        staffIds
      }
      try {
        var result = await toUrge(params)
        console.log('催办结果', result.success)
        if (result.success) {
          cmsNotice('success', '催办成功')
        }
        getDetail()
        emit('urgOk', result.success)
      } catch (e) {
        // cmsNotice('warning', '催办失败')
        emit('urgOk', false)
      }
    }
  })
}
onMounted(() => {
  documentId.value = route.query.documentId
  console.log('documentIddocumentIddocumentId', route.query.documentId)
})
</script>

<style lang="less" scoped>
.urg-btn {
  padding-top: 6px;
  cursor: pointer;
  color: #0f83ff;

  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }

  span {
    font-size: 14px;
    padding-left: 5px;
  }
}
</style>
