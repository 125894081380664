import {
    Modal
} from 'ant-design-vue'
import {
    createVNode,
} from 'vue'
import {
    ExclamationOutlined
} from '@ant-design/icons-vue'
export function useUrg(type) {
    // type staff 盖印人    approve 审批节点
    //   点击催办的提示
    function urgModel(onOk) {
        Modal.confirm({
            title: '提示',
            content: `是否对该${type == 'staff' ? '印章盖印人' : '节点审批人'}发起催办？`,
            centered: true,
            icon: createVNode(ExclamationOutlined),
            onOk: onOk
        })
    }
    return {
        urgModel
    }
}